import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import { element, func, object, oneOfType } from 'prop-types';

import { nextEnvironment, nextSeller } from '@/HOC/utils/nextProps';

import { EnvironmentProvider } from '@/client/contexts/EnvironmentContext';

import '@/client/styles/app.scss';

const LrpApp = ({ Component, pageProps, seller, environment }) => (
  <>
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Head>
    <EnvironmentProvider value={environment}>
      <Component {...pageProps} seller={seller} />
    </EnvironmentProvider>
  </>
);

LrpApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { req, res } = appContext.ctx;

  // when the user presses the back button, this getInitialProps gets called.
  // No req/res is available, so we need to reconstruct the returned props from another source
  if (process.browser && !req && !res) {
    const environment = nextEnvironment();
    const seller = nextSeller();

    return {
      ...appProps,
      seller,
      environment,
    };
  }

  const { seller, environmentContext } = res.locals;

  return {
    ...appProps,
    seller,
    environment: environmentContext,
  };
};

LrpApp.propTypes = {
  Component: oneOfType([element, func]),
  pageProps: object,
  seller: object,
  environment: object,
};

export default LrpApp;
